import { useMutation } from '@tanstack/react-query';
import { EditOrderResponse } from '@ts/accounts';

export type EditOrderArgs = {
	orderId: string;
	variantId: string;
};

const useEditOrder = () => {
	return useMutation(['edit-order'], async ({ orderId, variantId }: EditOrderArgs) => {
		const response = await fetch('/api/edit-order', {
			method: 'POST',
			body: JSON.stringify({
				orderId: `gid://shopify/Order/${orderId}`,
				pairCareVariantId: variantId,
			}),
		});

		const jsonData = await response.json();
		return jsonData as EditOrderResponse;
	});
};

export default useEditOrder;
